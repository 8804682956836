import React, { useState } from 'react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AppDispatch } from '../../reduxStore/Store';
import { useDispatch, useSelector } from 'react-redux';
import { DialogProps, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import { CellClickedEvent, ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';
import { Actions, ExtendedICellRendererParams } from './Actions';
import {
  deleteCourseAssessData,
  deleteCourseData,
  getAssessmentsByCourse,
  getCourseDetail,
} from '../../reduxStore/reducer/courseReducer';
import AddCourseAssessment from './AddCourseAssessment';
import { ServerSideGrid } from '../../utils/MasterGrid/ServerSideGrid';
import GridHeader3 from '../../utils/gridHeader/GridHeader3';
import { DownloadDialog } from './DownloadDialog';
import EditIcon from '@mui/icons-material/Edit';
import { MdDelete } from 'react-icons/md';
import Swal from 'sweetalert2';

function Course() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [openAssessment, setOpenAssessment] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [assessmentInitialValues, setAssessmentInitialValues] = useState<any>();
  const [gridApi, setGridApi] = useState<any>(null);
  const [masterGridparams, setMasterGridParams] = useState<any>(null);
  const [downloadDialog, setDownloadDialog] = useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const [searchQuery, setSearchQuery] = useState('');
  const [columnApi, setColumnApi] = useState<any>(null);
  const [openedParentRowEvent, setOpenedParentRowEvent] = useState<any>(null);
  const [downloadType, setDownloadType] = useState<string>(
    'DOWNLOAD ANONYMOUS DATA'
  );

  const getUserDataAndType = useSelector(
    (state: any) => state.register.getUserById
  );

  const getCourseDetails = (
    row: ExtendedICellRendererParams,
    scrollType: DialogProps['scroll']
  ) => {
    getCourseDetail(row.data).then((data) => {
      setAssessmentInitialValues(data);
      setOpenAssessment(true);
      setScroll(scrollType);
    });
  };

  let courseDefs: any = [
    {
      headerName: 'Year',
      field: 'year',
      minWidth: 80,
      maxWidth: 90,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Term',
      field: 'term',
      minWidth: 90,
      maxWidth: 100,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Fall', 'Spring', 'Summer', 'Winter'],
      },
    },
    {
      headerName: 'Course',
      field: 'course_details_name',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      filter: 'agTextColumnFilter',
      onCellClicked: (e: CellClickedEvent) => {
        window.open(`/course/assessment/${e.data.id}`, '_blank');
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      // headerClass: 'relative left-20',
      headerComponent: () => <div className='relative left-[44%]'>Action</div>,
      filter: false,
      sortable: false,
      minWidth: 240,
      cellRenderer: Actions,
      cellRendererParams: (params: ICellRendererParams) => ({
        ...params,
        handleUpdate,
        handleDelete,
        handleCopy,
        getCourseDetails,
        initialValues,
        setInitialValue,
      }),
    },
    {
      headerName: 'Instructor(s)',
      field: 'instructors',

      filter: false,
      sortable: false,
      cellRenderer: (row: ICellRendererParams) =>
        row.data.instructors.map((i: any) => i.name).join(', '),
    },
    {
      headerName: 'Assessment Coordinator(s)',
      field: 'assessment_coordinators',

      filter: false,
      sortable: false,
      cellRenderer: (row: ICellRendererParams) =>
        row.data.assessment_coordinators.map((i: any) => i.name).join(', '),
    },
    {
      headerName: 'Course Prefix',
      field: 'course_prefix_name',
      minWidth: 150,
      maxWidth: 160,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Course Number',
      field: 'number',
      // minWidth: 90,
      minWidth: 160,
      maxWidth: 170,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Organization',
      field: 'organization_name',
      minWidth: 250,
    },
    {
      headerName: 'Created By',
      field: 'created_by',
      hide: true,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter',
      cellRenderer: (row: ICellRendererParams) => {
        return moment(moment(row.data.created_at).utc())
          .local()
          .format('MM-DD-YYYY hh:mm a');
      },
      hide: true,
    },
  ];

  const handleUpdate = (data: any) => {
    getCourseDetail(data, navigate, '/editcourse');
  };

  const handleCopy = (data: any) => {
    getCourseDetail(data, navigate, '/addcourse');
  };

  const handleDelete = (data: any) => {
    dispatch(deleteCourseData(data, gridApi));
  };

  const onFilterTextBoxChanged = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchQuery(e.target.value);
  };

  const [initialValues, setInitialValue] = React.useState<any>();

  const handleCollapseClick = (rowId: any) => {
    if (gridApi) {
      const node = gridApi.getRowNode(rowId);
      if (node) {
        node.setExpanded(!node.expanded);
      }
    }
  };

  const assessmentGrid = {
    refreshStrategy: 'everything',
    detailGridOptions: {
      columnDefs: [
        {
          headerName: 'Assessment',
          field: 'official_name',
          width: 353,
          onCellClicked: (e: CellClickedEvent) => {
            navigate(`/administration/${e.data.id}/${e.data.courseId}`);
          },
        },
        // {
        //   headerName: 'Label',
        //   field: 'label',
        //   width: 353,
        //   // cellRenderer: ViewAdministration,
        //   // cellRendererParams: (params: ICellRendererParams) => ({
        //   //   params,
        //   // }),
        // },
        {
          headerName: 'Action',
          field: 'action',
          sortable: false,
          filter: false,
          headerClass: 'start-header',
          width: 100,
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <div className='flex justify-start'>
                <Tooltip title='Edit'>
                  <IconButton
                    onClick={() => {
                      //

                      const temp = params.data.id;
                      params.data.id = params.data.courseId;
                      getCourseDetail(params.data).then((data) => {
                        const dat = {
                          ...data,
                          official_name: params.data.official_name,
                          administration: params.data.no_of_administrations,
                          id: temp,
                          assessment_id: params.data.assessment_id,
                          no_of_administrations:
                            params.data.no_of_administrations,
                          type: params.data.type,
                        };

                        setInitialValue({
                          id: dat.id ? dat.id : '',
                          assessment_id: {
                            official_name: dat?.official_name
                              ? dat.official_name
                              : '',
                            assessment_id: dat?.assessment_id
                              ? dat.assessment_id
                              : '',
                            code: params?.data?.code ? params?.data?.code : '',
                            type: dat?.type ? dat.type : '',
                          },
                          no_of_administrations: dat?.no_of_administrations
                            ? dat.no_of_administrations
                            : 2,
                          created_by: '',
                        });

                        setAssessmentInitialValues(dat);
                        setIsEdit(true);
                        setOpenAssessment(true);
                      });
                      params.data.id = temp; //reassign the changed id
                      // navigate(`/`);
                    }}
                  >
                    <EditIcon className='float-right text-blue-600' />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Delete'>
                  <IconButton
                    onClick={() => {
                      Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        backdrop: true,
                        showCloseButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        allowOutsideClick: false,
                        allowEscapeKey: true,
                        width: 300,
                        heightAuto: false,
                        customClass: {
                          popup: 'swal-title-color',
                          icon: 'swal-title-icon',
                          confirmButton: 'swal-confirm',
                        },
                      }).then(async (result: any) => {
                        if (result.isConfirmed) {
                          // if(masterGridparams)
                          // handleCollapseClick(params.data.courseId)
                          dispatch(
                            deleteCourseAssessData(params, handleCollapseClick)
                          );
                        }
                      });
                    }}
                  >
                    <MdDelete className='float-right text-red-600' />
                  </IconButton>
                </Tooltip>
              </div>
            );
          },
        },
      ],
      enableCellChangeFlash: true,
      getRowId: (params: any) => {
        return params.data.id;
      },
      // detailRowHeight: 400,
      defaultColDef: {
        suppressCopyRowsToClipboard: false,
        flex: 1,
        resizable: true,
        sortable: true,
        width: 100,
      },
      getContextMenuItems: (params: any) => null,
    },
    getDetailRowData: (params: any) => {
      getAssessmentByCourse(params);
    },
  };

  const getAssessmentByCourse = (params: any) => {
    setMasterGridParams(params);
    getAssessmentsByCourse(params);
  };

  const textFieldProps = {
    value: searchQuery,
    onChange: onFilterTextBoxChanged,
  };
  const buttonProps = {
    button1: {
      children1: 'DOWNLOAD ANONYMOUS DATA',
      onClick: () => {
        setDownloadType('DOWNLOAD ANONYMOUS DATA');
        setDownloadDialog(!downloadDialog);
      },
      sm1: 'Anony..',
      sx: {
        visibility:
          getUserDataAndType?.data?.type == 'admin' ? 'none' : 'hidden',
      },
    },
    button2: {
      children2: 'Add Course',
      onClick: () => navigate('/addcourse'),
      sm2: 'Add',
    },
    button3: {
      children3: 'DOWNLOAD DATA',
      onClick: () => {
        setDownloadType('DOWNLOAD DATA');
        setDownloadDialog(!downloadDialog);
      },
      sm3: 'Data',
      sx: {
        visibility:
          getUserDataAndType?.data?.type == 'admin' ? 'none' : 'hidden',
      },
    },
  };

  const gridOptions = {
    onCellClicked: (e: CellClickedEvent) => {
      setOpenedParentRowEvent(e);
      if (e.colDef.headerName !== 'Action') {
        e.node.setExpanded(!e.node.expanded);
      }
    },
  };
  return (
    <main>
      <header className='header'>Courses</header>
      <GridHeader3 textFieldProps={textFieldProps} buttonProps={buttonProps} />

      <ServerSideGrid
        rowDataUrl='/course/'
        debouncedSearchQuery={searchQuery}
        columnDefs={courseDefs}
        gridApi={gridApi}
        columnApi={columnApi}
        setGridApi={setGridApi}
        setColumnApi={setColumnApi}
        // masterDetail
        detailRowHeight={325}
        // detailCellRendererParams={assessmentGrid}
        gridOptions={gridOptions}
      />

      {getUserDataAndType?.data?.type == 'admin' ? (
        <DownloadDialog
          downloadDialog={downloadDialog}
          setDownloadDialog={setDownloadDialog}
          type={downloadType}
        />
      ) : (
        ''
      )}

      {assessmentInitialValues && (
        <AddCourseAssessment
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          openAssessment={openAssessment}
          setOpenAssessment={setOpenAssessment}
          assessmentInitialValues={assessmentInitialValues}
          params={masterGridparams}
          getAssessmentByCourse={getAssessmentByCourse}
          scroll={scroll}
          initialValues={initialValues}
          setInitialValue={setInitialValue}
        />
      )}
    </main>
  );
}

export default Course;
