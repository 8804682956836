import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton, MenuItem, Menu, Typography } from '@mui/material';
import { GiOpenBook } from 'react-icons/gi';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from '../../images/Logos/lasso.png';
import Session from '../../utils/logout';
import TokenService from '../../api/token.service';
import {
  getStudentDetailsById,
  getUserById,
  getUserDetailsById,
} from '../../reduxStore/reducer/registerReducer';
import { AppDispatch } from '../../reduxStore/Store';
import jwtDecode from 'jwt-decode';
import Swal from 'sweetalert2';

const Header = () => {
  const session = Session();
  const user = localStorage.getItem('token');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const url = location.pathname;

  const loginCondition = url.includes('login');

  function Logout() {
    TokenService.RemoveAccessToken();
    TokenService.RemoveRefreshToken();
    localStorage.removeItem('role');
    localStorage.removeItem('hasRenderedBefore');
    localStorage.removeItem('hasRenderedBeforeInvite');
    localStorage.removeItem('theme');
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  }

  const getUserDataAndType = useSelector(
    (state: any) => state.register.getUserById
  );

  const data = {
    // define your properties here
  };

  useEffect(() => {
    if (
      Object.keys(JSON.parse(localStorage.getItem('token') || '{}')).length !==
      0
    )
      dispatch(
        getUserById({
          id: JSON.parse(localStorage.getItem('token') || '{}'),
        })
      );
  }, [dispatch]);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // dispatch(
    //   getUserById({
    //     id: `${JSON.parse(localStorage.getItem('token') || '{}')}`,
    //   })
    // );

    if (localStorage.getItem('token')) {
      const user: any = JSON.parse(localStorage.getItem('token') || '{}');

      const { type }: any = jwtDecode(user);
      setType(type);

      if (type === 'student') {
        //disp
        dispatch(getStudentDetailsById({ id: user }, setLoading));
      } else {
        // dispatch(getUserDetailsByIdAffiliate({ id: user }));
        dispatch(getUserDetailsById({ id: user }, setLoading));
      }
    }
  }, [dispatch]);

  const dummyMenuItems = [
    {
      title: 'My Account',
      link: '/myaccount',
    },
    {
      title: 'Help Center',
      link: '/helpcenter',
    },
    {
      title: 'Sign Out',
      link: '',
    },
  ];
  // if (getUserDataAndType?.data?.type === 'faculty') {
  //   dummyMenuItems.push({

  //   });
  // }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const nativeOnChange = (event: any) => {
    const detail = {
      selectedIndex: event.target.selectedIndex,
    };
    event.target.selectedIndex = 0;
    event.target.dispatchEvent(new CustomEvent('itemClick', { detail }));
  };

  const itemClick = (event: any) => {};

  const handleLogoClick = () => {
    if (user) {
      // If the user is logged in, perform the logout action
      navigate('/home');
    } else {
      if (location?.pathname === '/testquestion') {
        Swal.fire({
          text: `Assessment is ongoing; your data will not be saved. If you still need to go to the login page, click the 'Ok' button`,
          // text: '',
          icon: 'warning',
          showCancelButton: true, // Show the 'Stay' button
          confirmButtonText: 'OK', // Text for the 'OK' button
          cancelButtonText: 'Return to Assessment',
          showCloseButton: true,
          // Text for the 'Stay' button
        }).then((result) => {
          if (result.isConfirmed) {
            // If 'OK' is clicked, navigate to the login page
            navigate('/login');
          }
          // If 'Stay' or the background is clicked, the alert will close by default
        });
      } else {
        // If the user is not logged in and it's not the 'testquestion' page, navigate to the login page directly
        navigate('/login');
      }
    }
  };

  return (
    <div>
      <header className='text-gray-600 body-font'>
        <div
          className={`p-2 mx-auto flex flex-wrap justify-between items-center ${
            window.location.href.includes('lasso-staging.smcommerce.net') ||
            // window.location.href.includes('localhost:3000') ||
            window.location.href.includes(
              'https://devui-rit-bvd-lab-lasso.apps.nimbus.las.iastate.edu'
            )
              ? 'bg-red'
              : 'bg-header'
          } h-16`}
        >
          <div className='flex items-center gap-3 text-xs md:text-5xl font-bold'>
            <img
              onClick={handleLogoClick}
              src={logo}
              className='w-12 h-auto cursor-pointer'
              alt='...'
            />
            <h1 onClick={handleLogoClick} className='text-white cursor-pointer'>
              LASSO
              {(window.location.href.includes('lasso-staging.smcommerce.net') ||
                // window.location.href.includes('localhost:3000') ||
                window.location.href.includes(
                  'https://devui-rit-bvd-lab-lasso.apps.nimbus.las.iastate.edu'
                )) && <span> {`(Development)`}</span>}
            </h1>
          </div>

          {!user && !loginCondition ? (
            <></>
          ) : // <Button
          //   variant="contained"
          //   onClick={() => navigate('/login')}
          //   className="inline-flex items-center text-white bg-sign-in border-0 py-1 px-3 focus:outline-none hover:bg-blue-800 rounded text-base mt-4 md:mt-0"
          // >
          //   Sign In
          // </Button>
          loginCondition ? (
            <div></div>
          ) : (
            <>
              <IconButton
                aria-label='Account circle icon'
                size='large'
                onClick={handleClick}
              >
                <AccountCircleIcon
                  style={{ color: 'white' }}
                  fontSize='large'
                />
              </IconButton>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {dummyMenuItems.map(
                  (item) =>
                    // Conditionally render menu items based on 'type'
                    (type !== 'student' || item?.title !== 'Help Center') && (
                      <MenuItem
                        onClick={() => {
                          if (item.title === 'Sign Out') {
                            session.logoutAllTabs();
                          } else {
                            navigate(item.link);
                          }
                          setAnchorEl(null);
                          localStorage.removeItem('courseFormData');
                        }}
                        key={item.title}
                        value={item.title}
                      >
                        {item.title}
                      </MenuItem>
                    )
                )}
              </Menu>
            </>
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
