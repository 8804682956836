import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Container, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../reduxStore/Store';
import {
  StudentConfirmationData,
  getStudentConfirmation,
} from '../../../reduxStore/reducer/studentSignupReducer';
import Swal from 'sweetalert2';

const Confirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues: {
      studentEmail: '',
    },
    validationSchema: Yup.object({
      studentEmail: Yup.string()
        .email('Invalid email format')
        .required('Student email is required'),
    }),
    onSubmit: (values) => {
      dispatch(
        getStudentConfirmation(
          { studentConfirmationEmail: values.studentEmail },
          (data: StudentConfirmationData) => {
            if (data.length !== 0) {
              navigate('/studentSuccess');
            } else {
              formik.setErrors({
                studentEmail:
                  'You cannot currently make an account because the email address you entered has not been assigned to a course in our system. Sometimes this occurs because students forget to use their school email addresses.',
              });
            }
          },
          navigate
        )
      );
    },
  });

  return (
    <div>
      <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
        <Paper
          variant='outlined'
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <form onSubmit={formik.handleSubmit}>
            <h1>
              <b>Sign Up</b>
            </h1>
            <TextField
              label='Student Email'
              variant='outlined'
              fullWidth
              margin='normal'
              id='studentEmail'
              name='studentEmail'
              value={formik.values.studentEmail}
              onChange={formik.handleChange}
              error={
                formik.touched.studentEmail &&
                Boolean(formik.errors.studentEmail)
              }
              helperText={
                formik.touched.studentEmail && formik.errors.studentEmail
              }
              FormHelperTextProps={{
                sx: {
                  fontSize: '1.1em', // adjust this value to your needs
                },
              }}
            />

            <Button
              variant='contained'
              color='primary'
              type='submit'
              sx={{ marginTop: '7px' }}
              disabled={!formik.isValid}
            >
              Next
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default Confirmation;
