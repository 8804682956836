import api from './index';
import { PaginationProp } from '../../utils/globalInterface/GlobalInterfaces';

// const getSearchData = (data: string) =>
//   api.post('/assessment/find', { query: data });

const getAssessment = (data: PaginationProp) =>
  api.post('/assessment/getRows', data);

const searchAssessment = (data: any) =>
  api.post('/assessment/search', { query: data });

const takeassessment = (data: any) => api.post(`/assessment/take`, data);

const checkAssessmentStatus = (data: any) =>
  api.post(`/assessment/status`, data);

const getStudentAssessmentData = (data: any) =>
  api.post(`/assessment/student`, data);

const getAssessmentStudent = (data: any) =>
  api.get(`/assessment/student/${data.id}/${data.type}`);

const addAssessment = (data: any) => api.post(`/assessment`, data);

const updatePublishStatus = (data: any) =>
  api.post(`/assessment/publish`, data);

const updateAdaptivePublishStatus = (data: any) =>
  api.post(`/assessment/adaptive/publish`, data);

const addAssessmentVersion = (data: any) =>
  api.post(`/assessment/version`, data);

const editAssessment = (data: any, id: string) =>
  api.put(`/assessment/${id}`, data);

const deleteAssessment = (data: any) => api.delete(`/assessment/${data}`);

const deleteAdministrative = (data: any, id: string) =>
  //@ts-ignore
  api.delete(`/assessment/${id}`, data);

const getAssessmentDetails = () => api.get(`/assessment/details`);
const getTaspaAssessmentDetails = () => api.get(`/assessment/taspa-details`);

const getAssessmentById = (data: any) => api.get(`/assessment/id/${data}`);
const addCourseAssessment = (data: any) => api.post('/course/assessment', data);
const addCourseAssessSpecificQuestion = (data: any) =>
  api.post('/course/assessment/specific-question', data);

const editCourseAssessment = (data: any) => api.put('/course/assessment', data);

const addAssessmentDiscipline = (data: any) =>
  api.post(`/assessment/discipline`, data);

const getAssessmentDiscipline = () => api.get('/assessment/discipline');

const getAssessmentQuestion = (id: any) =>
  api.get(`/assessment/question/${id}`);

const addAdaptiveAssessment = (data: any) =>
  api.post(`/assessment/adaptive`, data);

const editAdaptiveAssessment = (data: any, id: string) =>
  api.put(`/assessment/adaptive/${id}`, data);

const getAdaptiveAssessment = (id: string) =>
  api.get(`/assessment/adaptive/${id}`);

const addAdaptiveItem = (data: any) =>
  api.post(`/assessment/adaptive/item`, data);

const editAdaptiveItem = (data: any, id: string) =>
  api.put(`/assessment/adaptive/item/${id}`, data);

const deleteAdaptiveItem = (data: any) =>
  api.post(`/assessment/adaptive/item/${data}`);

const fileUpload = (data: any) =>
  api.post(`/assessment/adaptive/item/file`, data);

const getAdaptiveAssessmentQuestion = (data: any) =>
  api.post(`/assessment/adaptive/id`, data);

const updateSession = (sessionStatus?: any) =>
  api.put(`/assessment/session-status`, sessionStatus);

const getAdaptiveAssessmentListWithTestItems = () =>
  api.get('/assessment/adaptive/test-items');

const saveAdaptiveAssessmentListWithTestItems = (data: any, id: any) =>
  api.post(`/assessment/adaptive/test-items/${id}`, data);

const getAdaptiveTestItems = (id: any) =>
  api.get(`/assessment/take/test-items/${id}`);

const takeTestItems = (data: any) =>
  api.post(`/assessment/take/test-items`, data);

const getSelectedAdaptiveTestItems = (id: any) =>
  api.get(`/assessment/adaptive/selected-test-items/${id}`);

const getRFiles = () => api.get(`/assessment/adaptive/file`);

const getAdaptiveAssessmentQuestionById = (id: any) =>
  api.get(`/assessment/adaptive/question/${id}`);

export {
  addCourseAssessSpecificQuestion,
  searchAssessment,
  getAssessmentDiscipline,
  addAssessmentDiscipline,
  checkAssessmentStatus,
  takeassessment,
  getAssessmentById,
  getAssessment,
  getAssessmentDetails,
  addCourseAssessment,
  addAssessment,
  editAssessment,
  deleteAdministrative,
  addAssessmentVersion,
  updatePublishStatus,
  updateAdaptivePublishStatus,
  getAssessmentStudent,
  deleteAssessment,
  getAssessmentQuestion,
  addAdaptiveAssessment,
  editAdaptiveAssessment,
  getAdaptiveAssessment,
  addAdaptiveItem,
  editAdaptiveItem,
  deleteAdaptiveItem,
  fileUpload,
  getAdaptiveAssessmentQuestion,
  updateSession,
  editCourseAssessment,
  getStudentAssessmentData,
  getAdaptiveAssessmentListWithTestItems,
  saveAdaptiveAssessmentListWithTestItems,
  getAdaptiveTestItems,
  takeTestItems,
  getSelectedAdaptiveTestItems,
  getTaspaAssessmentDetails,
  getRFiles,
  getAdaptiveAssessmentQuestionById,
};
