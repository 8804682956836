import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../Store';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as studentDashboardRoute from '../route/studentDashboardRoute';
import { GridApi } from 'ag-grid-community';
import Alert from '../../utils/Alert/Alert';
import moment from 'moment';

export interface AxiosError<T = any> extends Error {
  config: AxiosRequestConfig;
  code?: string;
  request?: object;
  data: object;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => object;
}

const initialState = {
  studentDashboardData: [],
  loading: false,
};

const getStudentDashboardList =
  (setCourse: any, setAssessment: any) => async (dispatch: AppDispatch) => {
    return studentDashboardRoute
      .getStudentDashboardList()
      .then((res: AxiosResponse) => {
        if (res?.data?.records) {
          const { overall_assessment_count, overall_course_count } =
            res.data.records;
          setCourse(overall_course_count);

          setAssessment(overall_assessment_count);
        }
      })
      .catch((err: AxiosError) => null);
  };

// const getStudentDashboardAssessmentList =
//   (option: any, setOptions: any, setCardsData: any | undefined = undefined) =>
//   async (dispatch: AppDispatch) => {
//     return studentDashboardRoute
//       .getStudentDashboardAssessmentList()
//       .then((res: AxiosResponse) => {
//         if (res?.data?.testStatusCounts) {
//           const { trueCount, falseCount } = res?.data?.testStatusCounts;
//           setOptions({
//             ...option,
//             data: [
//               {
//                 count: Number(trueCount),
//                 type: 'Assessment Taken',
//               },
//               { count: Number(falseCount), type: 'Remaining Assessment' },
//             ],
//           });
//         }
//         if (res?.data?.records) {
//           const data = [];
//           for (const course_assessment in res?.data?.records) {
//             // Iterate through each administration
//             const courseAssessment = res?.data?.records[course_assessment];
//             for (const administrationKey in res?.data?.records[
//               course_assessment
//             ].administrations) {
//               const administration =
//                 res?.data?.records[course_assessment].administrations[
//                   administrationKey
//                 ];
//               // Iterate through each student in the administration

//               const filteredStudent = administration.student.find(
//                 (d: any) =>
//                   d.course_student_id ===
//                   res?.data?.records[course_assessment].course_student_id
//               );

//               if (
//                 administration.start_date_time &&
//                 administration.end_date_time
//               ) {
//                 // for (const student of administration.student) {
//                 if (filteredStudent.test_status === false) {
//                   // Check if test_status is true
//                   data.push({
//                     assessment_name: courseAssessment.assessment_name,
//                     start_date_time: administration.start_date_time,
//                     end_date_time: administration.end_date_time,
//                     c_id: courseAssessment.c_id,
//                     cass_id: courseAssessment.cassid,
//                     ass_code: courseAssessment.ass_code,
//                     course_name: courseAssessment.course_name,
//                     adm_id: 'Administration ' + administrationKey,
//                     adm_no: administrationKey,

//                     student_id: filteredStudent.course_student_id, // Include student ID
//                     course_assessment_id: courseAssessment.course_assessment_id,
//                   });
//                 }
//                 // }
//               }
//             }
//           }
//           data.sort((a: any, b: any) => {
//             const dateA = new Date(a.end_date_time).getTime();
//             const dateB = new Date(b.end_date_time).getTime();
//             return dateA - dateB;
//           });
//
//           setCardsData(data);
//         }
//       })
//       .catch((err: AxiosError) =>
//   };
const getStudentDashboardAssessmentList =
  (option: any, setOptions: any, setCardsData: any | undefined = undefined) =>
  async (dispatch: AppDispatch) => {
    return studentDashboardRoute
      .getStudentDashboardAssessmentList()
      .then((res: AxiosResponse) => {
        if (res?.data?.testStatusCounts) {
          const { trueCount, falseCount } = res?.data?.testStatusCounts;
          setOptions({
            ...option,
            data: [
              {
                count: Number(trueCount),
                type: 'Assessment Taken',
              },
              { count: Number(falseCount), type: 'Remaining Assessment' },
            ],
          });
        }
        if (res?.data?.records) {
          const data = [];
          const currentDate = new Date(); // Get current date and time
          for (const course_assessment in res?.data?.records) {
            // Iterate through each administration
            const courseAssessment = res?.data?.records[course_assessment];
            for (const administrationKey in res?.data?.records[
              course_assessment
            ].administrations) {
              const administration =
                res?.data?.records[course_assessment].administrations[
                  administrationKey
                ];
              // Iterate through each student in the administration

              const filteredStudent = administration.student.find(
                (d: any) =>
                  d.course_student_id ===
                  res?.data?.records[course_assessment].course_student_id
              );

              if (
                administration.start_date_time &&
                administration.end_date_time
              ) {
                if (filteredStudent.test_status === false) {
                  // Compare end_date_time with current date and time
                  const endDate = new Date(administration.end_date_time);
                  const startDate = new Date(administration.start_date_time);

                  if (endDate > currentDate)
                    if (startDate <= currentDate && endDate > currentDate) {
                      // Include the data only if end_date_time is in the future
                      data.push({
                        assessment_name: courseAssessment.assessment_name,
                        start_date_time: administration.start_date_time,
                        end_date_time: administration.end_date_time,
                        c_id: courseAssessment.c_id,
                        cass_id: courseAssessment.cassid,
                        ass_code: courseAssessment.ass_code,
                        course_name: courseAssessment.course_name,
                        adm_id: 'Administration ' + administrationKey,
                        adm_no: administrationKey,
                        student_id: filteredStudent.course_student_id,
                        course_assessment_id:
                          courseAssessment.course_assessment_id,
                      });
                    }
                }
              }
            }
          }
          data.sort((a: any, b: any) => {
            const dateA = new Date(a.end_date_time).getTime();
            const dateB = new Date(b.end_date_time).getTime();
            return dateA - dateB;
          });
          //
          setCardsData(data);
        }
      })
      .catch((err: AxiosError) => null);
  };

const StudentDashboardSlice = createSlice({
  name: 'StudentDashboardSlice',
  initialState,
  reducers: {
    handleGetStudentDashboard: (state, action) => {
      state.studentDashboardData = action.payload;
    },

    handleLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { handleGetStudentDashboard, handleLoading } =
  StudentDashboardSlice.actions;

export { getStudentDashboardList, getStudentDashboardAssessmentList };

export default StudentDashboardSlice.reducer;
