import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Switch,
  InputAdornment,
} from '@mui/material';
// import { ColDef, ColGroupDef, GridOptions } from "ag-grid-community";
import AGGrid from '../../utils/MasterGrid/MasterGrid';
import Swal from 'sweetalert2';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { FaUserGraduate } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import axios from 'axios';
import { MdAllInbox, MdDelete, MdEdit } from 'react-icons/md';
import Course from '../course/Course';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Alert from '../../utils/Alert/Alert';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { studentFileupload } from '../../reduxStore/reducer/courseReducer';
import api from '../../reduxStore/route';
import { studentsBulkDelete } from '../../reduxStore/reducer/administrationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../reduxStore/Store';

export interface FileImport {
  course_id: any;
  first_name: any;
  middle_name: any;
  last_name: any;
  email_id: any;
  field1: any;
  field2: any;
  field3: any;
}
const FileImport = ({ props }: any) => {
  const dispatch = useDispatch<AppDispatch>();

  // const selectedData: any = useSelector(
  //   (state: RootState) => state.assessment.assessmentStatus
  // );

  // useEffect(() => {
  //   if (selectedData?.test_status === true) {
  //     Swal.fire({
  //       title: 'Assessment already taken',
  //       text: '',
  //       icon: 'info',
  //     }).then(() => {
  //       navigate('/login');
  //     });
  //   }
  // }, [selectedData]);

  const navigate = useNavigate();
  const location = useLocation();

  const gridRef = useRef<any>(null);

  const course_id = props?.data?.id;

  const setState = props?.data?.setStudentState;
  const [isTyping, setIsTyping] = useState(false);

  const [openStudentDialog, setOpenStudentDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleClickClose = () => {
    Alert.confirm(() => {
      setOpenStudentDialog(false);
      setInitialValue({
        course_id: course_id,
        first_name: '',
        middle_name: '',
        last_name: '',
        email_id: '',
        field1: '',
        field2: '',
        field3: '',
      });
      setIsTyping(false);
    });
  };

  const [initialValues, setInitialValue] = useState<FileImport>({
    course_id: course_id,
    first_name: '',
    middle_name: '',
    last_name: '',
    email_id: '',
    field1: '',
    field2: '',
    field3: '',
  });

  const validation = Yup.object({
    first_name: Yup.string().required('Required Field'),
    last_name: Yup.string().required('Required Field'),
    email_id: Yup.string()
      .email('Must be a valid email')
      .required('entered text is not a valid email'),
  });

  // const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    props?.setOpen(true);
  };
  const [courseCount, setCourseCount] = useState(0);

  const [gridApi, setGridApi] = useState<any>(null);

  const onGridReady = (e: any) => {
    setGridApi(e.api);
    getData();
  };

  const handleClose = () => {
    Alert.confirm(() => {
      props.setOpen(false);
    });
  };
  const [file, setFile] = useState<File | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [fileData, setFileData] = useState<any>();
  const [cronValue, setCronValue] = useState<any>();
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [bulkStudent, setBulkStudent] = useState<any>([]);

  const handleUploadClick = (event: any) => {
    // 👇 We redirect the click event onto the hidden input element

    const originalFiles: any = file;
    const formData = new FormData();
    formData.append('file', originalFiles);
    formData.append('courseid', props?.data?.id);

    const callback = () => {
      // Clear the file input and disable the upload button
      inputRef.current && (inputRef.current.value = '');
      setIsButtonDisabled(true);

      // Reset the file state to null
      setFile(null);

      getData();

      props?.setRefresh && props.setRefresh(true);
      setTimeout(() => {
        props?.setRefresh && props.setRefresh(false); // Set refresh flag to false
      }, 1000);
      props?.setStudentState(false);
    };

    studentFileupload(formData, callback);
  };

  const handleAddStudent = () => {};

  const handleDelete = async (row: any, action: any, restore: Boolean) => {
    const condition = `${action ? 'delete' : restore ? 'restore' : 'drop'}`;

    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${condition}  this student entry!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${condition} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = row;
        data.hardDelete = action;
        const url = `${process.env.REACT_APP_BASE_URL}/api/course_details/student/${data.id}/${course_id}/${action}/${restore}`;

        const config = {
          method: 'post',
          url: url,
          headers: {
            authorization: localStorage.getItem('token')
              ? `Bearer ${JSON.parse(localStorage.getItem('token') || '{}')}`
              : '',
            'Content-Type': 'application/json',
          },
        };
        await axios(config)
          .then(async (response) => {
            Swal.fire({
              icon: 'success',
              title: `Student entry ${
                action ? 'deleted' : restore ? 'restored' : 'dropped'
              }!`,
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              customClass: {
                container: 'swal-container',
              },
            });
            props?.setRefresh && props.setRefresh(true);
            await getData();
            props?.setRefresh && props.setRefresh(false);
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
            });
          });
      }
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const allowedExtensions = ['.csv'];
    const fileName = file.name;
    const fileExtension = fileName.slice(fileName.lastIndexOf('.'));

    if (!allowedExtensions.includes(fileExtension)) {
      Swal.fire('Invalid File', 'Please select a CSV file.', 'error');
      e.target.value = ''; // Clear the file input
      setIsButtonDisabled(true); // Disable the upload button
      return;
    }

    setFile(file);
    setIsButtonDisabled(false); // Enable the upload button
  };

  const [columnDefs, setColumnDefs] = useState<any[]>([
    {
      // headerCheckboxSelection: true,
      showDisabledCheckboxes: (params: any) => {
        const hasTakenAssessment = params?.data?.test_status.some(
          (t: any) => t === true
        );

        return hasTakenAssessment;
      },
      checkboxSelection: (params: any) => {
        const hasTakenAssessment = params?.data?.test_status.some(
          (t: any) => t === true
        );

        return !hasTakenAssessment;
      },
    },
    { headerName: 'First Name', field: 'first_name', sort: 'asc' },
    { headerName: 'Middle Name', field: 'middle_name' },
    { headerName: 'Last Name', field: 'last_name' },
    { headerName: 'Email', field: 'email_id', minWidth: 250 },
    { headerName: 'Opt. field 1', field: 'field1' },
    { headerName: 'Opt. field 2', field: 'field2' },
    { headerName: 'Opt. field 3', field: 'field3' },
    {
      headerName: 'Dropped',
      field: 'is_active',
      cellRenderer: (row: ICellRendererParams) => {
        const hasTakenAssessment = row?.data?.test_status.some(
          (t: any) => t === true
        );

        return (
          <Switch
            checked={!row?.data?.is_active}
            // disabled={!row?.data?.is_active}
            onChange={(e: any) =>
              handleDelete(row.data, false, !e.target.checked)
            }
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        );
      },
    },
    {
      headerName: 'Action',
      field: 'action',
      minWidth: 130,
      maxWidth: 140,

      cellRenderer: (row: ICellRendererParams) => {
        const hasTakenAssessment = row?.data?.test_status.some(
          (t: any) => t === true
        );

        return (
          <>
            <IconButton>
              <MdDelete
                className='text-delete-icon'
                onClick={() => {
                  if (hasTakenAssessment) {
                    Swal.fire({
                      title:
                        'Student has already taken an assessment in this course',
                      text: '',
                      icon: 'info',
                    });
                  } else {
                    handleDelete(row.data, true, false);
                  }
                }}
              />
            </IconButton>
          </>
        );
      },
    },
  ]);

  const singleMemberMailSend = (e: any) => {
    if (e?.email_id) {
      sendMail([{ id: e.id, email: e.email_id }]);
    }
  };

  const multiMemberMailSend = (e: any) => {
    const multiMail: any = [];
    e.forEach((element: any) => {
      multiMail.push({ id: element.id, email: element.email_id });
    });
    if (multiMail?.length) {
      sendMail(multiMail);
    }
  };

  const sendMail = (data: any) => {
    api
      .post(`/course_details/mail`, {
        mails: data,
        cronValue: cronValue,
      })
      .then((res) => {
        Alert.success({ title: 'Email reminder Sent', text: '' });
      });
  };

  const defaultColDef = useMemo<any>(() => {
    return {
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      sortable: true,
      // sortable: flag,

      filterParams: {
        suppressAndOrCondition: true,
        trimInput: true,
        buttons: ['reset', 'clear'],
      },
    };
  }, []);

  const getData = async () => {
    await api.get(`/course_details/file/${props.data.id}`).then((res) => {
      setCourseCount(res?.data?.records.length);
      const data = res?.data?.records || [];
      setFileData(data);
    });
  };

  const onBtnExport = useCallback(() => {
    gridApi.exportDataAsCsv({
      fileName: file?.name || 'students.csv',
      columnKeys: [
        'first_name',
        'middle_name',
        'last_name',
        'email_id',
        'field1',
        'field2',
        'field3',
      ],
    });
  }, [gridApi, file]);

  return (
    <div>
      {/* {!props?.name ? (
        <Tooltip title="Manage Students">
          <IconButton onClick={() => handleClickOpen()}>
            <FaUserGraduate className="text-edit-icon" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          onClick={() => handleClickOpen()}
          variant="contained"
          size="small"
          style={{ marginLeft: '13px' }}
        >
          <span>Manage Students</span>
        </Button>
      )} */}

      <Dialog
        // sx={{
        //   '& .MuiDialog-container': {
        //     '& .MuiPaper-root': {
        //       width: '100%',
        //       maxWidth: '1000px', // Set your width here
        //     },
        //   },
        // }}
        open={props?.open ? props.open : false}
        maxWidth={'lg'} // Change this to 'lg' or 'xl'
        fullWidth={true}
      >
        <DialogTitle className=' flex justify-between items-center h-12  bg-sky-800  text-white '>
          {/* {props.edit ? "Update" : "Add"} */}
          <div> Add Students to Assessment</div>
          <div>
            <IconButton
              size='small'
              sx={{ color: 'white' }}
              onClick={handleClose}
            >
              <AiOutlineClose />
            </IconButton>
          </div>

          {/* <Divider /> */}
        </DialogTitle>
        <DialogContent>
          {/* <div className=""> */}
          <div className='flex items-center gap-4 justify-between'>
            <div className='button-wrap flex my-1.5 items-center gap-5'>
              <div>
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  variant='contained'
                  // style={{ marginTop: "5px" }}
                  size='small'
                >
                  <label className='new-button' htmlFor='upload'>
                    Select Course Roster
                    <input
                      className='choose-file'
                      id='upload'
                      type='file'
                      ref={inputRef}
                      onChange={(e) => handleFileChange(e)}
                    />
                  </label>
                </Button>

                {/* <div>hii</div> */}
              </div>
              {file?.name}

              <Button
                sx={{ textTransform: 'capitalize' }}
                variant='contained'
                size='small'
                onClick={handleUploadClick}
                disabled={isButtonDisabled} // Disable the button if no file is selected
              >
                upload
              </Button>

              <div className='flex gap-2'>
                <span className=' font-bold'>
                  Roster count <span>:</span>
                </span>
                <span className=' text-blue-600'>
                  {courseCount ? courseCount : 0}
                </span>
              </div>
              <div className='flex'>
                <Link component='button' variant='body2' onClick={onBtnExport}>
                  Download Course Roster Template
                </Link>
              </div>
            </div>
            <div className='flex gap-4'>
              <div className={bulkStudent.length > 0 ? '' : 'hidden'}>
                <Button
                  variant='contained'
                  size='small'
                  sx={{ textTransform: 'initial' }}
                  onClick={() => gridRef.current.api.deselectAll()}
                >
                  Clear Selection
                </Button>
              </div>

              <div className={bulkStudent.length > 0 ? '' : 'hidden'}>
                <Button
                  variant='contained'
                  size='small'
                  color='error'
                  sx={{ textTransform: 'initial' }}
                  onClick={() => {
                    dispatch(
                      studentsBulkDelete(
                        { data: bulkStudent },
                        getData,
                        setBulkStudent
                      )
                    );
                  }}
                >
                  Batch Delete student
                </Button>
              </div>

              <div>
                <Button
                  variant='contained'
                  size='small'
                  sx={{ textTransform: 'initial' }}
                  onClick={() => {
                    setIsEdit(false);
                    setOpenStudentDialog(true);
                  }}
                >
                  Add a student
                </Button>
              </div>
            </div>
          </div>
          <div style={{ fontSize: '12px', color: 'red' }}> Only csv file *</div>

          <div
            className='ag-theme-alpine'
            style={{
              marginLeft: '0px',
              marginTop: '5px',
            }}
          >
            <AgGridReact
              rowSelection='multiple'
              suppressRowClickSelection={true}
              onSelectionChanged={(event) => {
                const selectedData = event.api
                  .getSelectedNodes()
                  .map((node) => node.data);

                const data = selectedData.map((e: any) => ({
                  course_id: e.course_id,
                  id: e.id,
                }));

                setBulkStudent(data);
              }}
              getRowClass={(params) => {
                const hasTakenAssessment = params?.data?.test_status.some(
                  (t: any) => t === true
                );

                return hasTakenAssessment ? 'disable-checkbox' : '';
              }}
              ref={gridRef}
              paginationPageSize={25}
              overlayNoRowsTemplate='No rows to show'
              suppressServerSideInfiniteScroll={false}
              rowData={fileData}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              pagination={true}
              onGridReady={onGridReady}
              cacheQuickFilter={true}
              domLayout={'autoHeight'}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: 'capitalize' }}
            variant='contained'
            autoFocus
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            sx={{ textTransform: 'capitalize' }}
            variant='contained'
            onClick={handleUploadClick}
            disabled={isButtonDisabled} // Disable the button if no file is selected
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openStudentDialog}>
        <DialogTitle sx={{ backgroundColor: '#1663ab', color: 'white' }}>
          <div className='flex justify-between items-center'>
            <div>{isEdit ? 'Update Student' : 'Add Student'}</div>
            <div>
              <AiOutlineClose
                onClick={handleClickClose}
                className='cursor-pointer	'
              />
            </div>
          </div>
        </DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values) => {
            const url = `/course_details/add`;

            await api
              .post(url, values)
              .then(async (response) => {
                if (
                  response.data &&
                  response?.data?.message !== 'No unique data found'
                ) {
                  Swal.fire({
                    title: 'Student(s) added!',
                    icon: 'success',
                    customClass: {
                      container: 'swal-container',
                    },
                  });
                  props?.setRefresh && props.setRefresh(true);
                  await getData();
                  props?.setRefresh && props.setRefresh(false);
                } else if (response?.data?.message === 'No unique data found') {
                  Swal.fire({
                    title: 'Email Id already exists!',
                    icon: 'info',
                    customClass: {
                      container: 'swal-container',
                    },
                  });
                }
              })
              .catch((err) => {
                // Handle errors here
              });
            setOpenStudentDialog(false);
            setIsTyping(false);
          }}
        >
          {({
            values,
            isValid,
            handleChange,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            handleBlur,
          }) => (
            <DialogContent
              sx={{
                width: '600px',
                height: 'fitContent',
                overflowX: 'hidden',
              }}
            >
              <Form>
                <Field
                  as={TextField}
                  id='firstName'
                  label='First Name'
                  variant='standard'
                  name='first_name'
                  value={values.first_name}
                  error={errors.first_name && touched.first_name}
                  helperText={
                    errors.first_name && touched.first_name
                      ? errors.first_name
                      : ''
                  }
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('first_name', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.first_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.first_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                  className='capitalize'
                  fullWidth
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                />

                <Field
                  as={TextField}
                  id='middleName'
                  label='Middle Name'
                  variant='standard'
                  name='middle_name'
                  value={values.middle_name}
                  className='capitalize'
                  fullWidth
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('middle_name', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.middle_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.middle_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  as={TextField}
                  id='lastName'
                  label='Last Name'
                  variant='standard'
                  name='last_name'
                  value={values.last_name}
                  error={errors.last_name && touched.last_name}
                  helperText={
                    errors.last_name && touched.last_name
                      ? errors.last_name
                      : ''
                  }
                  className='capitalize'
                  fullWidth
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('last_name', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.last_name ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.last_name && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                <Field
                  as={TextField}
                  id='email'
                  label='Email'
                  variant='standard'
                  name='email_id'
                  value={values.email_id}
                  className='capitalize'
                  fullWidth
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                  helperText={errors.email_id ? errors.email_id : ''}
                  error={Boolean(errors.email_id)}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('email_id', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.email_id ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.email_id && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                {/* Field 1 */}
                <Field
                  as={TextField}
                  id='field1'
                  label='Opt. field 1'
                  variant='standard'
                  name='field1'
                  value={values.field1}
                  className='capitalize'
                  fullWidth
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('field1', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.field1 ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.field1 && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                {/* Field 2 */}
                <Field
                  as={TextField}
                  id='field2'
                  label='Opt. field 2'
                  variant='standard'
                  name='field2'
                  value={values.field2}
                  className='capitalize'
                  fullWidth
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('field2', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.field2 ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.field2 && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                {/* Field 3 */}
                <Field
                  as={TextField}
                  id='field3'
                  label='Opt. field 3'
                  variant='standard'
                  name='field3'
                  value={values.field3}
                  className='capitalize'
                  fullWidth
                  sx={{ marginBottom: '20px', marginTop: '20px' }}
                  onChange={(e: any) => {
                    handleChange(e);
                    setFieldValue('field3', e.target.value, true); // force validation
                    setIsTyping(true);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                    setIsTyping(false);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isTyping &&
                          (errors.field3 ? (
                            <span style={{ color: 'red' }}>✖</span>
                          ) : (
                            values.field3 && (
                              <span style={{ color: 'green' }}>✔</span>
                            )
                          ))}
                      </InputAdornment>
                    ),
                  }}
                />

                <div className='flex gap-3 justify-end'>
                  <Button
                    variant='contained'
                    style={{ textTransform: 'capitalize' }}
                    // fullWidth
                    onClick={handleClickClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    style={{ textTransform: 'capitalize' }}
                    type='submit'
                    // fullWidth
                    disabled={!isValid}
                    onClick={handleAddStudent}
                  >
                    {isEdit ? 'Update' : 'Add'}
                  </Button>
                </div>
              </Form>
            </DialogContent>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default FileImport;
