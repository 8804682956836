import {
  Box,
  RadioGroup,
  Radio,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Container,
  Paper,
} from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Role = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  if (!sessionStorage.getItem('role'))
    sessionStorage.setItem(
      'role',
      location?.state?.role_in_class ? location?.state?.role_in_class : ''
    );
  const [value, setValue] = React.useState(sessionStorage.getItem('role'));

  const handleChange = (event: any) => {
    setValue(event.target.value);
    location.state.role_in_class = event.target.value;
    sessionStorage.setItem('role', event.target.value);
  };
  // useEffect(() => {
  //     dispatch(getAllMetaDataByType('role_in_class'));
  // }, [dispatch]);
  // const metaData: any = useSelector((state: RootState) => state.metaData);

  return (
    <Container component='main' maxWidth='sm' sx={{ mb: 4 }}>
      <Paper
        variant='outlined'
        sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}
      >
        <FormControl component='fieldset'>
          <FormLabel
            component='legend'
            sx={{ fontSize: 'h5.fontSize', fontWeight: 'bold', mb: 2 }}
          >
            What is your role in this class?
          </FormLabel>
          <RadioGroup
            aria-label='role'
            name='role'
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value='student'
              control={<Radio />}
              label='Student'
            />
            <FormControlLabel
              value='learningAssistant'
              control={<Radio />}
              label='Learning Assistant/near-peer'
            />
            <FormControlLabel
              value='teachingAssistant'
              control={<Radio />}
              label='Teaching Assistant'
            />
            <FormControlLabel
              value='faculty'
              control={<Radio />}
              label='Faculty'
            />
          </RadioGroup>
        </FormControl>
      </Paper>
      <div className='w-full flex justify-center '>
        <Button
          size='medium'
          variant='contained'
          disabled={!Boolean(value)}
          onClick={() => {
            if (location?.state?.login) {
              navigate('/student/testquestion', {
                state: location.state,
              });
            } else {
              navigate('/testquestion', {
                state: location.state,
              });
            }
          }}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};
export default Role;
